<swiper-container class="novedades-swiper" pagination="true" space-between="30"
  centered-slides="true" autoplay-delay="2500" autoplay-disable-on-interaction="false">
  <swiper-slide *ngFor="let novedad of novedades">
    <div class="novedad-slide" [ngStyle]="{'background-image': 'url(' + novedad.image + ')'}">
      <div class="novedad-overlay">
        <h2>{{ novedad.title }}</h2>
        <p>{{ novedad.description }}</p>
      </div>
    </div>
  </swiper-slide>
</swiper-container>
