<div class="header-container">
  <div class="logo-container">
    <img src="../../../../assets/images/LOGOTIPO_OFICIAL.png" alt="Logo">
  </div>

  <nav class="nav-container">
    <a (click)="ruteo('inicio')" >Inicio</a>
    <a routerLink="/courses">Cursos</a>
    <a routerLink="/about">Nosotros</a>
    <a routerLink="/contact">Contacto</a>
  </nav>
  
  <div class="user-container">
    <nb-actions size="small" *ngIf="isAuthenticated; else guestLinks">
      <nb-action class="user-action">
        <nb-user [nbContextMenu]="userMenu" [onlyPicture]="userPictureOnly" [name]="user?.name" [picture]="user?.picture"></nb-user>
      </nb-action>
    </nb-actions>
    <ng-template #guestLinks >
      <a (click)="ruteo('login')" class="btn btn-primary" style="color: white; margin-right: 10px;" >Login</a>
      <a (click)="ruteo('register')" class="btn btn-secondary" style="color: white;">Register</a>
    </ng-template>
  </div>
</div>

<!-- Sección Principal con imagen, texto y botón -->
<!-- <section class="main-section">
  <div class="main-content">
    <h1>Bienvenido a la Plataforma Educativa</h1>
    <p>Explora nuestros cursos diseñados para mejorar tus habilidades.</p>
    <a class="cta-button" href="#courses">Ver Cursos</a>
  </div>
</section> -->

<!-- Barra de Categorías -->
<!-- <section class="categories-section">
  <div class="category" *ngFor="let category of categories">
    <h2>{{ category.name }}</h2>
    <p>{{ category.description }}</p>
  </div>
</section> -->
