<section class="categories-section">
  <div class="category col-2" (click)="onCategoryClick(service.name)" *ngFor="let service of services">
    <h2>{{ service.name }}</h2>
    <p>{{ service.description }}</p>
  </div>

</section>
<!-- <div class="category2">
  <a  (click)="toggleSidebar()" class="sidebar-toggle2" >
    <nb-icon icon="menu-2-outline"></nb-icon>
  </a>
  <nb-menu [items]="menu"></nb-menu>
  <nb-sidebar  tag="menu-sidebar2" responsive>
    <ng-content select="nb-menu"></ng-content>
  </nb-sidebar>
</div> -->
<div class="category2">
  <button mat-button [matMenuTriggerFor]="menu">
    <mat-icon>more_vert</mat-icon>Servicios
  </button>
  <mat-menu #menu="matMenu">
    <ng-container  *ngFor="let service of services">
      <button mat-menu-item (click)="onCategoryClick(service.name)">
        <div>
          <strong>{{ service.name }}</strong>
          <p style="margin: 0; font-size: 0.85rem;">{{ service.description }}</p>
        </div>
      </button>
    </ng-container>
  </mat-menu>
</div>
