import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';
import { NbAuthService, NbAuthJWTToken } from '@nebular/auth';
import { UserData } from '../../../@core/data/users';
import { LayoutService } from '../../../@core/utils';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { UserService } from '../../../@auth/components/userLoger.service';
import { jwtDecode } from "jwt-decode";
import { SharedService } from '../../../@auth/components/shared.service';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;
  isAuthenticated: boolean;

  categories = [
    { name: 'Biología', description: 'Si te apasiona la naturaleza' },
    { name: 'Matematica', description: 'Lo tuyo son los números?' },
    { name: 'Psicología', description: 'Si te interesa la mente humana' },
    { name: 'Programación', description: 'Para los interesados en el código fuente' },
    { name: 'Derecho', description: 'Abogados y juristas' },
  ];

  themes = [
    { value: 'default', name: 'Light' },
    { value: 'dark', name: 'Dark' },
    { value: 'cosmic', name: 'Cosmic' },
    { value: 'corporate', name: 'Corporate' },
    { value: 'custom', name: 'Custom' },
    { value: 'material-light', name: 'Material Light' },
  ];

  currentTheme = 'default';

  userMenu = [{ title: 'Profile' }, { title: 'Log out', link: '/auth/logout' }];

  constructor(private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private userService: UserData,
    private layoutService: LayoutService,
    private breakpointService: NbMediaBreakpointsService,
    private authService: NbAuthService,
    private router: Router,
    private userStorageService: UserService,
    private sharedService: SharedService) {
  }

  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;


    this.authService.onTokenChange()
      .pipe(takeUntil(this.destroy$))
      .subscribe((token: NbAuthJWTToken) => {
        if (token.isValid()) {
          const decodedToken = jwtDecode(token.getValue());
          console.log('Decoded Token:', decodedToken);

          this.user = decodedToken;
          this.isAuthenticated = true;
          this.userStorageService.saveUser(this.user);
        } else {
          this.user = null;
          this.isAuthenticated = false;
          this.userStorageService.clearUser();
        }
      });



    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe(isLessThanXl => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);



    this.sharedService.isAuthenticated$
      .pipe(takeUntil(this.destroy$))
      .subscribe(isAuthenticated => this.isAuthenticated = isAuthenticated);

    const token = localStorage.getItem('auth_app_token');
    if (token) {
      this.user = jwtDecode(token);
      this.isAuthenticated = true;
      this.sharedService.setAuthenticated(true);
    } else {
      this.isAuthenticated = false;
      this.sharedService.setAuthenticated(false);
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();
    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  ruteo(path: string) {
    const routes = {
      'inicio': '/',
      'login': '/auth/login',
      'register': '/auth/register'
    };
    this.router.navigateByUrl(routes[path]);
  }

  logout() {
    this.authService.logout('email').subscribe({
      next: () => {
        this.user = null;
        this.isAuthenticated = false;
        this.userStorageService.clearUser();
        this.router.navigateByUrl('/auth/login');
      },
      error: (err) => {
        console.error('Logout failed', err);
      }
    });
  }

  // toggleVisibility() {
  //   this.sharedService.setVisible(false); // Cambiar a false si quieres ocultar
  // }

}