import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EMAIL_PATTERN } from '../../@auth/components';
import { ContactData, Contact } from '../../@core/interfaces/contact';

@Component({
  selector: 'ngx-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit{
  contactForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private contactService: ContactData
  )
  { }

  ngOnInit(): void {
      this.initForm();
  }

  initForm() {
    this.contactForm = this.fb.group({
      firstName:this.fb.control('',[Validators.required, Validators.minLength(3), Validators.maxLength(20)]),
      lastName:this.fb.control('',[Validators.required, Validators.minLength(3), Validators.maxLength(20)]),
      email:this.fb.control('',[Validators.required, Validators.pattern(EMAIL_PATTERN)]),
      phone:this.fb.control('',[Validators.required, Validators.minLength(10), Validators.maxLength(15)]),
      message:this.fb.control('',[Validators.required, Validators.minLength(10), Validators.maxLength(500)])
    });
  }

  onSubmit() {
    const data = this.contactForm.value;
    this.contactService.sendContact(data).subscribe((response) => {
      if (response.status === 200) {
        this.contactForm.reset();
      }
    });
  }
}
