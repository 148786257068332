import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Observable } from 'rxjs';
import { GetDocumentDetailResponse, GetDocumentsResponse } from '../../interfaces/documents';

@Injectable({
  providedIn: 'root'
})
export class DocumentsApi {

  constructor(private api: HttpService) { }

  getDocuments(pagina: number, cantElementos: number): Observable<GetDocumentsResponse> {
    return this.api.get(`api/v1/document?pagina=${pagina}&cantElementos=${cantElementos}`);
  }

  uploadDocument(formData: FormData): Observable<any> {
    return this.api.post('api/v1/document', formData);
  }

  getDocument(id: string): Observable<GetDocumentDetailResponse> {
    return this.api.get(`api/v1/document/${id}`);
  }


  delete(id: number): Observable<any> {
    return this.api.delete(`api/v1/document/${id}`);
}
  putLikes(id: string): Observable<any> {
    return this.api.put(`api/v1/document/likes/${id}`);
  }

  getRecentDocuments(value: string): Observable<GetDocumentsResponse> {
    return this.api.get(`api/v1/document/searchBy?key=category&value=${value}`);

  }

  updateDocument(id: string, fromData: FormData): Observable<any> {
    return this.api.put(`api/v1/document/${id}`, fromData);
  }

  searchDocuments(key: string, value: string): Observable<GetDocumentsResponse> {
    const endpoint = `api/v1/document/searchBy?key=${key}&value=${value}`;
    return this.api.get(endpoint);
  }


  filterDocuments(params: Record<string, string>): Observable<GetDocumentsResponse> {
    const query = new URLSearchParams(params).toString();
    const endpoint = `api/v1/document/filtros?${query}`;
    return this.api.get(endpoint);
  }
  getDocumentRecientes(): Observable<GetDocumentsResponse> {
    return this.api.get('api/v1/document/recientes?pagina=1&cantElementos=10');
  }

  getDocumentMasVistos(): Observable<GetDocumentsResponse> {
    return this.api.get('api/v1/document/masvistos?pagina=1&cantElementos=10');
  }

  getDocumentMasVendidos(): Observable<GetDocumentsResponse> {
    return this.api.get('api/v1/document/masvendidos?pagina=1&cantElementos=10');
  }
}
