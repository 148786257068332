import { Observable } from "rxjs"

export interface PostPaymentResponse {
    result: boolean;
    status: number;
    data: boolean;
    timestamp:string;
  }

  export interface PostPayment {
    userId: string
    amount: number,
    isSubscription: boolean,
    status: string,
    subscriptionType: string,
    documentIds: number[],
    guestEmail: string,
}

export interface Payment {
    paymentId: string,
    userId: string
    email: string,
    firstName: string,
    amount: string,
    paymentDate: string,
    state: string
}

export abstract class PaymentData {
    abstract getPayments(): Observable<Payment[]>;
}