<p class="sub-title">Registrate en la plataforma</p>

<nb-alert *ngIf="showMessages.error && errors?.length && !submitted" outline="danger" role="alert">
  <p class="alert-title"><b>Error!</b></p>
  <ul class="alert-message-list">
    <li *ngFor="let error of errors" class="alert-message">{{ error }}</li>
  </ul>
</nb-alert>

<nb-alert *ngIf="showMessages.success && messages?.length && !submitted" outline="success" role="alert">
  <p class="alert-title"><b>Genial!</b></p>
  <ul class="alert-message-list">
    <li *ngFor="let message of messages" class="alert-message">{{ message }}</li>
  </ul>
</nb-alert>

<form [formGroup]="registerForm" (ngSubmit)="register()">
  <div class="form-control-group">
    <input nbInput
           fullWidth
           id="input-username"
           formControlName="username"
           [status]="username.dirty ? (username.invalid  ? 'danger' : 'success') : (username.invalid && username.touched ? 'danger' : 'basic')"
           [attr.aria-invalid]="username.invalid && username.touched ? true : null"
           placeholder="UserName">
  </div>

  <div class="form-control-group">
    <input nbInput
           fullWidth
           id="input-email"
           formControlName="email"
           [status]="email.dirty ? (email.invalid  ? 'danger' : 'success') : (email.invalid && email.touched ? 'danger' : 'basic')"
           [attr.aria-invalid]="email.invalid && email.touched ? true : null"
           placeholder="Email">
  </div>

  <div class="form-control-group">
    <input nbInput
           fullWidth
           id="input-password"
           formControlName="password"
           type="password"
           [status]="password.dirty ? (password.invalid  ? 'danger' : 'success') : (password.invalid && password.touched ? 'danger' : 'basic')"
           placeholder="Contraseña">
  </div>

  <div class="form-control-group">
    <input nbInput
           fullWidth
           id="input-confirm-password"
           formControlName="confirmPassword"
           type="password"
           [status]="confirmPassword.dirty ? (confirmPassword.invalid || confirmPassword.hasError('mismatch') ? 'danger' : 'success') : (confirmPassword.invalid && confirmPassword.touched ? 'danger' : 'basic')"
           placeholder="Confirmar Contraseña">
  </div>

  <div class="form-control-group">
    <input nbInput
           fullWidth
           id="input-firstname"
           formControlName="firstname"
           [status]="firstname.dirty ? (firstname.invalid  ? 'danger' : 'success') : (firstname.invalid && firstname.touched ? 'danger' : 'basic')"
           [attr.aria-invalid]="firstname.invalid && firstname.touched ? true : null"
           placeholder="Nombre">
  </div>

  <div class="form-control-group">
    <input nbInput
           fullWidth
           id="input-lastname"
           formControlName="lastname"
           [status]="lastname.dirty ? (lastname.invalid  ? 'danger' : 'success') : (lastname.invalid && lastname.touched ? 'danger' : 'basic')"
           [attr.aria-invalid]="lastname.invalid && lastname.touched ? true : null"
           placeholder="Apellido">
  </div>

  <div class="form-control-group">
    <input nbInput
           fullWidth
           id="input-country"
           formControlName="country"
           [status]="country.dirty ? (country.invalid  ? 'danger' : 'success') : (country.invalid && country.touched ? 'danger' : 'basic')"
           [attr.aria-invalid]="country.invalid && country.touched ? true : null"
           placeholder="País">
  </div>

  

  <button nbButton fullWidth status="primary" size="large"
          type="submit" [disabled]="submitted || !registerForm.valid"
          [class.btn-pulse]="submitted">
    Registrarse
  </button>
  <button   class="custom-google-button" (click)="loginWithGoogle()">
    <img src="/assets/iconos/search.png" alt="Google Icon" style="height: 25px; margin-right: 8px;"> Ingresar con Google
  </button>
</form>