import { Injectable } from "@angular/core";
import { Payment, PaymentData, PostPayment, PostPaymentResponse } from "../../interfaces/payments";
import { PaymentsApi } from "../api/payments.api";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
    providedIn: 'root'
  })

export class PaymentService extends PaymentData {
    constructor(private api: PaymentsApi) {
        super();    
    }

    getPayments(): Observable<Payment[]> {
        return this.api.getPayments().pipe(
            map(response => response.data)
        );
    }

    postPayment(payment: PostPayment): Observable<PostPaymentResponse> {
        return this.api.postPayment(payment);
    }
}