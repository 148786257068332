import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'ngx-one-column-layout',
  styleUrls: ['./one-column.layout.scss'],
  template: `
    <nb-layout windowMode>
      <nb-layout-header >
        <ngx-header></ngx-header>
      </nb-layout-header>

      <nb-sidebar #miSidebar [ngClass]="isInSiteModule ? 'sidebar-toggle' : 'menu-sidebar fixed left'" tag="menu-sidebar" responsive>
        <ng-content select="nb-menu"></ng-content>
      </nb-sidebar>

      <nb-layout-column class="main-layout">
      <ngx-main-section *ngIf="!isInPagesAdminModule"></ngx-main-section>
      <ngx-categories-section *ngIf="!isInPagesAdminModule"></ngx-categories-section>
        <ng-content select="router-outlet"></ng-content>
      </nb-layout-column>

      <nb-layout-footer fixed>
        <ngx-footer *ngIf="!isInPagesAdminModule"></ngx-footer>
      </nb-layout-footer>
    </nb-layout>
  `,
})
export class OneColumnLayoutComponent implements AfterViewInit {
  @ViewChild('miSidebar', { static: false, read: ElementRef }) miSidebar!: ElementRef;


  isInSiteModule: boolean;
  isInPagesAdminModule: boolean;


  constructor(private router: Router) {
    this.isInSiteModule = this.router.url.startsWith('/site');
    this.isInPagesAdminModule = this.router.url.startsWith('/pages-admin');
  }

  ngAfterViewInit(): void {

    if (this.miSidebar?.nativeElement) {

      this.ocultarSidebar();
    } else {

  }

  }

  ocultarSidebar() {
    if (this.isInSiteModule) {
      const sidebar = this.miSidebar.nativeElement;
      if (sidebar.classList.contains('fixed')) {

        sidebar.classList.remove('fixed');
      }

    }
  }
}
