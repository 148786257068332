import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-main-section',
  templateUrl: './main-section.component.html',
  styleUrls: ['./main-section.component.scss']
})
export class MainSectionComponent  {
  novedades = [
    {
      image: 'assets/images/camera1.jpg',
      title: 'Novedad 1',
      description: 'Descubre la primera novedad que tenemos para ti.'
    },
    {
      image: 'assets/images/camera2.jpg',
      title: 'Novedad 2',
      description: 'Nuevos artículos y más información.'
    },
    {
      image: 'assets/images/camera3.jpg',
      title: 'Novedad 3',
      description: 'Explora esta tercera novedad con contenido exclusivo.'
    },
    {
      image: 'assets/images/camera4.jpg',
      title: 'Novedad 4',
      description: 'No te pierdas esta oportunidad única.'
    }
  ];


}
