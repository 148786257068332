import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbAuthResult, NbAuthService } from '@nebular/auth';
import { SharedService } from '../shared.service';
import { UserService } from '../userLoger.service';

@Component({
  selector: 'ngx-logout',
  template: '',
})
export class LogoutComponent implements OnInit {

  constructor(private authService: NbAuthService,
     private router: Router,
      private sharedService: SharedService,
      private userStorageService: UserService) {}

      ngOnInit(): void {
        this.authService.logout('email').subscribe((result: NbAuthResult) => {
          if (result.isSuccess()) {
            localStorage.removeItem('auth_app_token');
            this.sharedService.setAuthenticated(false);
            this.userStorageService.clearUser();
            this.router.navigateByUrl('/auth/login');
          } else {
            console.error('Logout failed', result.getErrors());
          }
        });
      }
    
    
}