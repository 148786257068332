import { Injectable } from '@angular/core';
import { User } from '../../@core/data/users';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private readonly USER_KEY = 'loggedInUser';

  constructor() {}

  saveUser(user: void): void {
    localStorage.setItem(this.USER_KEY, JSON.stringify(user));
  }

  getUser(): void {
    const user = localStorage.getItem(this.USER_KEY);
    return user ? JSON.parse(user) : null;
  }

  clearUser(): void {
    localStorage.removeItem(this.USER_KEY);
  }
}
