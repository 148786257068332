import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  templateUrl: `./footer.component.html`,
})
export class FooterComponent {
  constructor(private router: Router) {}


  navigateToFragmentAyuda(fragment: string) {
    this.router.navigate(['/site/ayuda'], { fragment }).then(() => {
      const element = document.getElementById(fragment);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    });
  }


navigateToFragment(fragment: string) {
  this.router.navigate(['/site/legales'], { fragment }).then(() => {
    const element = document.getElementById(fragment);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  });
}
navigateToFragmentAcercade(fragment: string) {
  this.router.navigate(['/site/acercade'], { fragment }).then(() => {
    const element = document.getElementById(fragment);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
    });
  }
}

