import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NbAuthService } from '@nebular/auth';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: NbAuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.authService.getToken().pipe(
      switchMap(token => {
        if (token && token.getValue()) {
          const cloned = req.clone({
            headers: req.headers.set('Authorization', `Bearer ${token.getValue()}`)
          });
          return next.handle(cloned);
        } else {
          return next.handle(req);
        }
      })
    );
  }
}