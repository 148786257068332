import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'ngx-categories-section',
  templateUrl: './categories-section.component.html',
  styleUrls: ['./categories-section.component.scss']
})
export class CategoriesSectionComponent {
  categories = [
    {
      name: 'Biología',
      description: 'Si te apasiona la naturaleza'
    },
    {
      name: 'Matematica',
      description: 'Lo tuyo son los números?'
    },
    {
      name: 'Psicología',
      description: 'Si te interesa la mente humana'
    },
    {
      name: 'Programación',
      description: 'Para amantes del coding'
    },
    {
      name: 'Derecho',
      description: 'Abogados y juristas'
    },
  ]

  constructor(private router: Router) {}

  onCategoryClick() {
    this.router.navigate(['/site/categorias']);
  }
}
