import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from './http.service';
import { User } from '../../interfaces/users';

interface UsersApiResponse {
  result: boolean;
  data: User[];
  timestamp: string;
  status: number;
}

@Injectable({
  providedIn: 'root'
})
export class UsersApi {

  constructor(private api: HttpService) { }

  getUsers(): Observable<UsersApiResponse> { // Cambiar el tipo de retorno aquí
    return this.api.get('api/v1/dashboard/users');
  }

  delete(id): Observable<any> {
    return this.api.delete(`api/v1/dashboard/user/${id}`);
  }
}
