import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Servicios, ServiciosData } from '../../../@core/interfaces/servicios';

@Component({
  selector: 'ngx-categories-section',
  templateUrl: './categories-section.component.html',
  styleUrls: ['./categories-section.component.scss']
})
export class CategoriesSectionComponent implements OnInit {
  services: Servicios[];

  constructor(private router: Router,
              private servicesService: ServiciosData
  ) {}

  ngOnInit() {
    this.servicesService.getServicios().subscribe(response => {
      this.services = response.data;
    });
  }

  onCategoryClick(service: string) {
    this.router.navigate([`/site/categorias/${service}`]);
  }
  toggleSidebar() {

  }
}
