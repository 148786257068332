import { Observable } from "rxjs";

export interface User {
  id: string;
  name: string;
  email: string;
  totalFacturas: string;
  totalPagado: string;
  //avatar: string;
  //roles: string[];
  //permissions: string[];
}


export abstract class UserData {
  abstract getUsers(): Observable<User[]>;
  abstract delete(id: number): Observable<any>;
}
