import { Injectable } from '@angular/core';
import { User, UserData } from '../../interfaces/users';
import { Observable } from 'rxjs';
import { UsersApi } from '../api/users.api';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UsersService extends UserData {

  constructor(private api: UsersApi) {
    super();
   }

  getUsers(): Observable<User[]> {
    return this.api.getUsers().pipe(
      map(response => response.data)
    )
  }

  delete(id: number): Observable<any> {
    return this.api.delete(id);
  }
}
