<footer class="footer">
    <div class="footer-section acerca">
      <h4>Acerca de</h4>
      <ul>
        <li><a aria-label="Acerca de Carpeta digital">Acerca de Carpeta Digital</a></li>
        <li><a aria-label="Prensa">Prensa</a></li>
        <li><a aria-label="¡Únete a nuestro equipo!">¡Únete a nuestro equipo!</a></li>
        <li><a aria-label="Contáctanos">Contáctanos</a></li>
        <li><a aria-label="Invita a tus amigos">Invita a tus amigos</a></li>
      </ul>
    </div>

    <div class="footer-section ayuda">
      <h4>Ayuda</h4>
      <ul>
        <li><a aria-label="Ayuda / Preguntas frecuentes">Ayuda / Preguntas frecuentes</a></li>
        <li><a aria-label="Accesibilidad">Accesibilidad</a></li>
        <li><a aria-label="Ayuda para compra">Ayuda para compra</a></li>
      </ul>
    </div>

    <div class="footer-section legal">
      <a><h4>Legal</h4></a>
      <ul>
        <li><a [routerLink]="['/site/legales']" fragment="terminos" aria-label="Términos">Términos</a></li>
        <li><a [routerLink]="['/site/legales']" fragment="privacidad" aria-label="Privacidad">Privacidad</a></li>
        <li><a [routerLink]="['/site/legales']" fragment="copyright" aria-label="Copyright">Copyright</a></li>
        <li><a [routerLink]="['/site/legales']" fragment="cookies" aria-label="Preferencias de cookies">Preferencias de cookies</a></li>
        <li><a [routerLink]="['/site/legales']" fragment="no-vender" aria-label="No vender ni compartir mi información personal">No vender ni compartir mi información personal</a></li>
      </ul>
    </div>

    <div class="footer-section social">
      <h4>Social</h4>
      <div class="redes">
          <a target="_blank" aria-label="Facebook" class="ion ion-social-facebook"></a>
          <a target="_blank" aria-label="Twitter" class="ion ion-social-twitter"></a>
          <a target="_blank" aria-label="LinkedIn" class="ion ion-social-linkedin"></a>
          <a target="_blank" aria-label="Instagram" class="ion ion-social-instagram"></a>
      </div>
    </div>
  </footer>
